import React from 'react'
import { Icon } from 'antd'

import Profile from './profile/Profile';

import PayRateTimeSheet from '../dashboard/timeSheet/PayRateTimeSheet';
import payrollTimeSheet from '../dashboard/payrollTimeSheet/index';
import ShiftTimeSheetContainer from './ShiftTimeSheet/ShiftTimeSheetContainer';
import SWMSMastersContainer from './SWMSMasters/SWMSMastersContainer';

import OHSMastersContainer from './ReportManagement/OHSMasterContainer';

const ContractorsContainer = React.lazy(() => import('../v2/Contractors/ContractorsContainer'));
const QuotesContainer = React.lazy(() => import('../v2/Quotes/QuotesContainer'));
const ReportRoot = React.lazy(() => import('../v2/Reporting/ReportRoot'));
const PriorityManagementContainer = React.lazy(() => import('../v2/PriorityManagement/PriorityManagementContainer'));
const SiteManagementContainer = React.lazy(() => import('../v2/SiteManagement/SiteManagementContainer'));
const DashboardContainer = React.lazy(() => import("../v2/Dashboard/DashboardContainer"));
const PayClassificationManagementContainer = React.lazy(() => import("../v2/PayClassificationManagement/PayClassificationManagementContainer"));
const ServiceTypeManagementContainer = React.lazy(() => import("../v2/ServiceTypeManagement/ServiceTypeManagementContainer"));
const TimesheetsContainer = React.lazy(() => import('../v2/Timesheets/TimesheetsContainer'));
const RfqMainContainer = React.lazy(() => import("../v2/RequestForQuotes/RfqMainContainer"));
const CalendarContainer = React.lazy(() => import("../v2/Calendar/ContainerParent"));
const RosteringWrapper = React.lazy(() => import("../v2/Rostering/RosteringWrapper"));
const ConsequencesContainer = React.lazy(() => import('../v2/Consequences/ConsequencesContainer'));
const LikelihoodContainer = React.lazy(() => import("../v2/Likelihood/LikelihoodContainer"));
const LicenseTypesContainer = React.lazy(() => import("../v2/LicenseTypes/LicenseTypesContainer"));
const SmsTemplatesContainer = React.lazy(() => import("../v2/SmsTemplates/SmsTemplatesContainer"));
const AdditionalFieldsContainer = React.lazy(() => import("../v2/AdditionalFields/AdditionalFieldsContainer"));
const SwmsMasters = React.lazy(() => import("../v2/SwmsMasters/SwmsMastersContainer"));
const AccountReceivablesContainer = React.lazy(() => import("../v2/AccountReceivables/AccountReceivablesContainer"));
const OhsContainer = React.lazy(() => import("../v2/OH&S/OhsContainer"));
const AccountsPayableContainer = React.lazy(() => import("../v2/AccountsPayable/AccountsPayableContainer"));
const ExpenseAccountsContainer = React.lazy(() => import("../v2/ExpenseAccounts/ExpenseAccountsContainer"));
const ContractsContainer = React.lazy(() => import("../v2/Contracts/ContractsContainer"));
const TasksContainer = React.lazy(() => import('../v2/Tasks/TasksParentContainer'));
const CertificatesContainer = React.lazy(() => import("../v2/CertificatesMamagement/CertificatesContainer"));
const RolesContainer = React.lazy(() => import("../v2/RolesManagement/RolesManagementContainer"));
const OrganizationContainer = React.lazy(() => import("../v2/Organization/OrganizationContainer"));
const ClientContainer = React.lazy(() => import("../v2/ClientManagement/ClientManagementContainer"));
const EmailTemplateContainer = React.lazy(() => import("../v2/EmailTemplateManagement/EmailTemplateContainer"));
const PdfTemplateContainer = React.lazy(() => import("../v2/PdfTemplateManagement/PdfTemplateContainer"));
const UserManagementContainer = React.lazy(() => import("../v2/UserManagement/UserManagementContainer"));

export const NAV_MENU = [

  {
    iconType: (icon) => <Icon type={icon ? icon : "dashboard"} theme="filled"></Icon>,
    linkTo: "/dashboard",
    component: DashboardContainer,
  },
  {
    iconType: () => <span className='anticon' style={{ width: "22px", fontSize: "22px" }}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
        <path fill="currentColor" d="M0 96l576 0c0-35.3-28.7-64-64-64H64C28.7 32 0 60.7 0 96zm0 32V416c0 35.3 28.7 64 64 64H512c35.3 0 64-28.7 64-64V128H0zM64 405.3c0-29.5 23.9-53.3 53.3-53.3H234.7c29.5 0 53.3 23.9 53.3 53.3c0 5.9-4.8 10.7-10.7 10.7H74.7c-5.9 0-10.7-4.8-10.7-10.7zM176 192a64 64 0 1 1 0 128 64 64 0 1 1 0-128zm176 16c0-8.8 7.2-16 16-16H496c8.8 0 16 7.2 16 16s-7.2 16-16 16H368c-8.8 0-16-7.2-16-16zm0 64c0-8.8 7.2-16 16-16H496c8.8 0 16 7.2 16 16s-7.2 16-16 16H368c-8.8 0-16-7.2-16-16zm0 64c0-8.8 7.2-16 16-16H496c8.8 0 16 7.2 16 16s-7.2 16-16 16H368c-8.8 0-16-7.2-16-16z" />
      </svg>
    </span>,
    linkTo: "/profile",
    component: Profile
  },
  {
    iconType: (icon) => <i className="anticon material-icons">{icon ? icon : "account_balance"}</i>,
    linkTo: "/organization",
    component: OrganizationContainer,
  },
  {
    iconType: () => <span className='anticon' style={{ width: "22px", fontSize: "22px" }}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
        <path fill="currentColor" d="M64 32C28.7 32 0 60.7 0 96L0 416c0 35.3 28.7 64 64 64l448 0c35.3 0 64-28.7 64-64l0-320c0-35.3-28.7-64-64-64L64 32zm80 256l64 0c44.2 0 80 35.8 80 80c0 8.8-7.2 16-16 16L80 384c-8.8 0-16-7.2-16-16c0-44.2 35.8-80 80-80zm-32-96a64 64 0 1 1 128 0 64 64 0 1 1 -128 0zm256-32l128 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-128 0c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64l128 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-128 0c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64l128 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-128 0c-8.8 0-16-7.2-16-16s7.2-16 16-16z" />
      </svg>
    </span>,
    linkTo: "/clients",
    component: ClientContainer
  },
  {
    iconType: () => <span className='anticon' style={{ width: "22px", fontSize: "22px" }}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
        <path fill="currentColor" d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512l388.6 0c10 0 18.8-4.9 24.2-12.5l-99.2-99.2c-14.9-14.9-23.3-35.1-23.3-56.1l0-33c-15.9-4.7-32.8-7.2-50.3-7.2l-91.4 0zM384 224c-17.7 0-32 14.3-32 32l0 82.7c0 17 6.7 33.3 18.7 45.3L478.1 491.3c18.7 18.7 49.1 18.7 67.9 0l73.4-73.4c18.7-18.7 18.7-49.1 0-67.9L512 242.7c-12-12-28.3-18.7-45.3-18.7L384 224zm24 80a24 24 0 1 1 48 0 24 24 0 1 1 -48 0z" />
      </svg>
    </span>,
    theme: "filled",
    linkTo: "/rolesmanagement",
    component: RolesContainer,
  },
  {
    iconType: () => <span className='anticon' style={{ width: "22px", fontSize: "22px" }}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
        <path fill="currentColor" d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512l388.6 0c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304l-91.4 0z" />
      </svg>
    </span>,
    linkTo: '/userManagement',
    component: UserManagementContainer
  },
  {
    iconType: () => <span className='anticon' style={{ width: "22px", fontSize: "22px" }}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path fill="currentColor" strokeWidth={2} d="M32 32c17.7 0 32 14.3 32 32V400c0 8.8 7.2 16 16 16H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H80c-44.2 0-80-35.8-80-80V64C0 46.3 14.3 32 32 32zm96 96c0-17.7 14.3-32 32-32l96 0c17.7 0 32 14.3 32 32s-14.3 32-32 32H160c-17.7 0-32-14.3-32-32zm96 64H352c17.7 0 32 14.3 32 32s-14.3 32-32 32H224c-17.7 0-32-14.3-32-32s14.3-32 32-32zm160 96h64c17.7 0 32 14.3 32 32s-14.3 32-32 32H384c-17.7 0-32-14.3-32-32s14.3-32 32-32z" />
      </svg>
    </span>,
    linkTo: "/calendar",
    component: CalendarContainer
  },
  {
    iconType: () => <span className='anticon' style={{ width: "22px", fontSize: "22px" }}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path fill="currentColor" strokeWidth={2} d="M128 0c17.7 0 32 14.3 32 32V64H288V32c0-17.7 14.3-32 32-32s32 14.3 32 32V64h48c26.5 0 48 21.5 48 48v48H0V112C0 85.5 21.5 64 48 64H96V32c0-17.7 14.3-32 32-32zM0 192H448V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V192zm80 64c-8.8 0-16 7.2-16 16v64c0 8.8 7.2 16 16 16H368c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H80z" />
      </svg>
    </span>,
    linkTo: "/rostering",
    component: RosteringWrapper
  },
  {
    iconType: () => <span className='anticon'>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" style={{ width: "30px" }}>
        <polygon fill="currentColor" points="42.87 56.93 48.22 62.28 51.44 59.08 46.04 53.69 40.69 48.33 37.47 51.53 42.87 56.93" />
        <path fill="currentColor" d="M79.27,35.77l6.8-6.8c.04-.11,.11-.2,.2-.27,.08-.08,.17-.15,.27-.2l7.52-7.54-15.05-15.05-14.84,14.78,15.08,15.08Z" />
        <path fill="currentColor" d="M87.3,31.69l-6.92,6.99-.03,.03c-.54,.52-1.4,.51-1.92-.03l-17.06-17.03c-.54-.55-.54-1.43,0-1.97l7.03-7.01-1.63-1.63c-.42-.41-.98-.63-1.57-.64-.58,0-1.14,.23-1.57,.64l-10.57,10.46c-.83,.82-1.3,1.94-1.29,3.11v9.66c0,.37-.15,.72-.41,.98l-3.83,3.83c-1.16,1.17-1.16,3.05,0,4.22l4.54,4.54,4.54,4.54c1.17,1.16,3.05,1.16,4.22,0l3.83-3.83c.26-.26,.61-.4,.98-.41h9.69c1.17,0,2.29-.46,3.11-1.29l10.46-10.43c.41-.42,.63-.98,.64-1.57,0-.58-.23-1.14-.64-1.57l-1.59-1.57Z" />
        <path fill="currentColor" d="M20.87,64.05l-14.76,14.78,15.08,15.08,8.25-8.24c.11-.2,.28-.37,.48-.48l6.03-6.06-15.08-15.08Z" />
        <path fill="currentColor" d="M53.55,61.17l-3.15,3.11c-.53,.54-1.26,.84-2.02,.84-.76,0-1.48-.3-2.02-.84l-5.4-5.38-5.47-5.31c-.54-.53-.84-1.26-.84-2.02,0-.76,.3-1.48,.84-2.02l3.2-3.2-3.58-3.67h-.01c-.89-.9-2.33-.89-3.21,0L12.02,62.49h-.01c-.88,.9-.88,2.34,.01,3.22l1.59,1.72,6.28-6.26c.26-.26,.61-.41,.98-.41,.37,0,.74,.14,1,.41l17.03,16.97,.03,.03c.52,.54,.51,1.4-.03,1.92l-6.28,6.28,1.54,1.57h.01c.89,.9,2.33,.89,3.21,0l19.85-19.87c.88-.88,.88-2.31,0-3.2l-3.67-3.7Z" />
      </svg>
    </span>,
    linkTo: "/swms",
    component: SwmsMasters
  },
  {
    iconType: () => <div className="anticon" ><i className=" fa fa-file-text-o"></i></div>,
    linkTo: "/payRates",
    component: PayRateTimeSheet
  },
  {
    iconType: () => <div className="anticon" ><i className=" fa fa-file-text-o"></i></div>,
    linkTo: "/payRoll",
    component: payrollTimeSheet
  },
  // {
  //   iconType: (icon) => <i className="anticon material-icons">{icon ? icon : "account_balance"}</i>,
  //   linkTo: "/job_calendar",
  //   component: JobCalendarContainer
  // },
  {
    iconType: () => <i className="anticon material-icons">markunread_mailbox</i>,
    linkTo: "/accounts_receivable",
    component: AccountReceivablesContainer
  },
  {
    iconType: () => <span className='anticon' style={{ width: "22px", fontSize: "22px" }}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
        <path fill="currentColor" d="M0 48C0 21.5 21.5 0 48 0H336c26.5 0 48 21.5 48 48V232.2c-39.1 32.3-64 81.1-64 135.8c0 49.5 20.4 94.2 53.3 126.2C364.5 505.1 351.1 512 336 512H240V432c0-26.5-21.5-48-48-48s-48 21.5-48 48v80H48c-26.5 0-48-21.5-48-48V48zM80 224c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V240c0-8.8-7.2-16-16-16H80zm80 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V240c0-8.8-7.2-16-16-16H176c-8.8 0-16 7.2-16 16zm112-16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V240c0-8.8-7.2-16-16-16H272zM64 112v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V112c0-8.8-7.2-16-16-16H80c-8.8 0-16 7.2-16 16zM176 96c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V112c0-8.8-7.2-16-16-16H176zm80 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V112c0-8.8-7.2-16-16-16H272c-8.8 0-16 7.2-16 16zm96 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm140.7-67.3c-6.2 6.2-6.2 16.4 0 22.6L521.4 352H432c-8.8 0-16 7.2-16 16s7.2 16 16 16h89.4l-28.7 28.7c-6.2 6.2-6.2 16.4 0 22.6s16.4 6.2 22.6 0l56-56c6.2-6.2 6.2-16.4 0-22.6l-56-56c-6.2-6.2-16.4-6.2-22.6 0z" />
      </svg>
    </span>,
    linkTo: "/accounts-payable",
    component: AccountsPayableContainer
  },
  {
    iconType: () => <i className="anticon material-icons">handyman</i>,
    linkTo: "/contractors_management",
    component: () => <ContractorsContainer />
  },
  {
    iconType: () => <i className="anticon material-icons">star</i>,
    linkTo: "/priority_management",
    component: () => <PriorityManagementContainer />
  },
  {
    iconType: () => <i className="anticon material-icons">shop</i>,
    linkTo: "/site_management",
    component: () => <SiteManagementContainer />
  },
  {
    iconType: () => <span className='anticon' style={{ width: "22px", fontSize: "22px" }}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path fill="currentColor" d="M64 64c0-17.7-14.3-32-32-32S0 46.3 0 64V400c0 44.2 35.8 80 80 80H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H80c-8.8 0-16-7.2-16-16V64zm406.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L320 210.7l-57.4-57.4c-12.5-12.5-32.8-12.5-45.3 0l-112 112c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L240 221.3l57.4 57.4c12.5 12.5 32.8 12.5 45.3 0l128-128z" />
      </svg>
    </span>,
    linkTo: "/reports",
    component: () => <ReportRoot />
  },
  {
    iconType: () => <span className='anticon' style={{ width: "22px", fontSize: "22px" }}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
        <path fill="currentColor" d="M64 0C28.7 0 0 28.7 0 64L0 448c0 35.3 28.7 64 64 64l256 0c35.3 0 64-28.7 64-64l0-288-128 0c-17.7 0-32-14.3-32-32L224 0 64 0zM256 0l0 128 128 0L256 0zM112 256l160 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-160 0c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64l160 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-160 0c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64l160 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-160 0c-8.8 0-16-7.2-16-16s7.2-16 16-16z" />
      </svg>
    </span>,
    linkTo: "/request_for_quotes",
    component: () => <RfqMainContainer />
  },
  {
    iconType: () => <span className='anticon' style={{ width: "22px", fontSize: "22px" }}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
        <path fill="currentColor" d="M176 0c-17.7 0-32 14.3-32 32s14.3 32 32 32l16 0 0 34.4C92.3 113.8 16 200 16 304c0 114.9 93.1 208 208 208s208-93.1 208-208c0-41.8-12.3-80.7-33.5-113.2l24.1-24.1c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L355.7 143c-28.1-23-62.2-38.8-99.7-44.6L256 64l16 0c17.7 0 32-14.3 32-32s-14.3-32-32-32L224 0 176 0zm72 192l0 128c0 13.3-10.7 24-24 24s-24-10.7-24-24l0-128c0-13.3 10.7-24 24-24s24 10.7 24 24z" />
      </svg>
    </span>,
    linkTo: "/timesheet",
    component: () => <TimesheetsContainer />
  },
  {
    iconType: () => <span className='anticon' style={{ width: "22px", fontSize: "22px" }}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
        <path fill="currentColor" d="M0 64C0 28.7 28.7 0 64 0L224 0l0 128c0 17.7 14.3 32 32 32l128 0 0 288c0 35.3-28.7 64-64 64L64 512c-35.3 0-64-28.7-64-64L0 64zm384 64l-128 0L256 0 384 128z" />
      </svg>
    </span>,
    linkTo: "/quotes",
    component: () => <QuotesContainer />
  },
  {
    iconType: () => <i className="anticon material-icons">donut_small</i>,
    linkTo: "/pay_classification_management",
    component: PayClassificationManagementContainer
  },
  {
    iconType: () => <i className="anticon material-icons">group_work</i>,
    linkTo: "/service_type_management",
    component: ServiceTypeManagementContainer
  },
  {
    iconType: () => <span className='anticon' style={{ width: "22px", fontSize: "22px" }}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
        <path fill="currentColor" d="M48 0C21.5 0 0 21.5 0 48V464c0 26.5 21.5 48 48 48h96V432c0-26.5 21.5-48 48-48s48 21.5 48 48v80h96c15.1 0 28.5-6.9 37.3-17.8C340.4 462.2 320 417.5 320 368c0-54.7 24.9-103.5 64-135.8V48c0-26.5-21.5-48-48-48H48zM64 240c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V240zm112-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H176c-8.8 0-16-7.2-16-16V240c0-8.8 7.2-16 16-16zm80 16c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H272c-8.8 0-16-7.2-16-16V240zM80 96h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16zm80 16c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H176c-8.8 0-16-7.2-16-16V112zM272 96h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H272c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16zM496 512a144 144 0 1 0 0-288 144 144 0 1 0 0 288zm0-96a24 24 0 1 1 0 48 24 24 0 1 1 0-48zm0-144c8.8 0 16 7.2 16 16v80c0 8.8-7.2 16-16 16s-16-7.2-16-16V288c0-8.8 7.2-16 16-16z" />
      </svg>
    </span>,
    linkTo: "/consequences",
    component: ConsequencesContainer
  },
  {
    iconType: () => <span className='anticon' style={{ width: "22px", fontSize: "22px" }}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
        <path fill="currentColor" d="M48 0C21.5 0 0 21.5 0 48V464c0 26.5 21.5 48 48 48h96V432c0-26.5 21.5-48 48-48s48 21.5 48 48v80h96c26.5 0 48-21.5 48-48V48c0-26.5-21.5-48-48-48H48zM64 240c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V240zm112-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H176c-8.8 0-16-7.2-16-16V240c0-8.8 7.2-16 16-16zm80 16c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H272c-8.8 0-16-7.2-16-16V240zM80 96h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16zm80 16c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H176c-8.8 0-16-7.2-16-16V112zM272 96h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H272c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16zM448 0c-17.7 0-32 14.3-32 32V512h64V192H624c8.8 0 16-7.2 16-16V48c0-8.8-7.2-16-16-16H480c0-17.7-14.3-32-32-32z" />
      </svg>
    </span>,
    linkTo: "/likelihood",
    component: LikelihoodContainer
  },
  {
    iconType: () => <span className='anticon' style={{ width: "22px", fontSize: "22px" }}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
        <path fill="currentColor" d="M0 96l576 0c0-35.3-28.7-64-64-64H64C28.7 32 0 60.7 0 96zm0 32V416c0 35.3 28.7 64 64 64H512c35.3 0 64-28.7 64-64V128H0zM64 405.3c0-29.5 23.9-53.3 53.3-53.3H234.7c29.5 0 53.3 23.9 53.3 53.3c0 5.9-4.8 10.7-10.7 10.7H74.7c-5.9 0-10.7-4.8-10.7-10.7zM176 192a64 64 0 1 1 0 128 64 64 0 1 1 0-128zm176 16c0-8.8 7.2-16 16-16H496c8.8 0 16 7.2 16 16s-7.2 16-16 16H368c-8.8 0-16-7.2-16-16zm0 64c0-8.8 7.2-16 16-16H496c8.8 0 16 7.2 16 16s-7.2 16-16 16H368c-8.8 0-16-7.2-16-16zm0 64c0-8.8 7.2-16 16-16H496c8.8 0 16 7.2 16 16s-7.2 16-16 16H368c-8.8 0-16-7.2-16-16z" />
      </svg>
    </span>,
    linkTo: "/license-types",
    component: LicenseTypesContainer
  },
  {
    iconType: () => <span className='anticon' style={{ width: "22px", fontSize: "22px" }}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
        <path fill="currentColor" d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H288V368c0-26.5 21.5-48 48-48H448V96c0-35.3-28.7-64-64-64H64zM448 352H402.7 336c-8.8 0-16 7.2-16 16v66.7V480l32-32 64-64 32-32z" />
      </svg>
    </span>,
    linkTo: "/sms-templates",
    component: SmsTemplatesContainer
  },
  {
    iconType: () => <span className='anticon' style={{ width: "22px", fontSize: "22px" }}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
        <path fill="currentColor" d="M96 0C78.3 0 64 14.3 64 32v96h64V32c0-17.7-14.3-32-32-32zM288 0c-17.7 0-32 14.3-32 32v96h64V32c0-17.7-14.3-32-32-32zM32 160c-17.7 0-32 14.3-32 32s14.3 32 32 32v32c0 77.4 55 142 128 156.8V480c0 17.7 14.3 32 32 32s32-14.3 32-32V412.8c12.3-2.5 24.1-6.4 35.1-11.5c-2.1-10.8-3.1-21.9-3.1-33.3c0-80.3 53.8-148 127.3-169.2c.5-2.2 .7-4.5 .7-6.8c0-17.7-14.3-32-32-32H32zM432 512a144 144 0 1 0 0-288 144 144 0 1 0 0 288zm16-208v48h48c8.8 0 16 7.2 16 16s-7.2 16-16 16H448v48c0 8.8-7.2 16-16 16s-16-7.2-16-16V384H368c-8.8 0-16-7.2-16-16s7.2-16 16-16h48V304c0-8.8 7.2-16 16-16s16 7.2 16 16z" />
      </svg>
    </span>,
    linkTo: "/additional-fields",
    component: AdditionalFieldsContainer
  }, {
    iconType: () => <span className='anticon' style={{ width: "22px", fontSize: "22px" }}>
      <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512">
        <path fill="currentColor" d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V428.7c-2.7 1.1-5.4 2-8.2 2.7l-60.1 15c-3 .7-6 1.2-9 1.4c-.9 .1-1.8 .2-2.7 .2H240c-6.1 0-11.6-3.4-14.3-8.8l-8.8-17.7c-1.7-3.4-5.1-5.5-8.8-5.5s-7.2 2.1-8.8 5.5l-8.8 17.7c-2.9 5.9-9.2 9.4-15.7 8.8s-12.1-5.1-13.9-11.3L144 381l-9.8 32.8c-6.1 20.3-24.8 34.2-46 34.2H80c-8.8 0-16-7.2-16-16s7.2-16 16-16h8.2c7.1 0 13.3-4.6 15.3-11.4l14.9-49.5c3.4-11.3 13.8-19.1 25.6-19.1s22.2 7.8 25.6 19.1l11.6 38.6c7.4-6.2 16.8-9.7 26.8-9.7c15.9 0 30.4 9 37.5 23.2l4.4 8.8h8.9c-3.1-8.8-3.7-18.4-1.4-27.8l15-60.1c2.8-11.3 8.6-21.5 16.8-29.7L384 203.6V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM549.8 139.7c-15.6-15.6-40.9-15.6-56.6 0l-29.4 29.4 71 71 29.4-29.4c15.6-15.6 15.6-40.9 0-56.6l-14.4-14.4zM311.9 321c-4.1 4.1-7 9.2-8.4 14.9l-15 60.1c-1.4 5.5 .2 11.2 4.2 15.2s9.7 5.6 15.2 4.2l60.1-15c5.6-1.4 10.8-4.3 14.9-8.4L512.1 262.7l-71-71L311.9 321z" />
      </svg>
    </span>,
    linkTo: "/ohs",
    component: OhsContainer
  },
  {
    iconType: () => <span className='anticon' style={{ width: "22px", fontSize: "22px" }}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path fill="currentColor" d="M211 7.3C205 1 196-1.4 187.6 .8s-14.9 8.9-17.1 17.3L154.7 80.6l-62-17.5c-8.4-2.4-17.4 0-23.5 6.1s-8.5 15.1-6.1 23.5l17.5 62L18.1 170.6c-8.4 2.1-15 8.7-17.3 17.1S1 205 7.3 211l46.2 45L7.3 301C1 307-1.4 316 .8 324.4s8.9 14.9 17.3 17.1l62.5 15.8-17.5 62c-2.4 8.4 0 17.4 6.1 23.5s15.1 8.5 23.5 6.1l62-17.5 15.8 62.5c2.1 8.4 8.7 15 17.1 17.3s17.3-.2 23.4-6.4l45-46.2 45 46.2c6.1 6.2 15 8.7 23.4 6.4s14.9-8.9 17.1-17.3l15.8-62.5 62 17.5c8.4 2.4 17.4 0 23.5-6.1s8.5-15.1 6.1-23.5l-17.5-62 62.5-15.8c8.4-2.1 15-8.7 17.3-17.1s-.2-17.4-6.4-23.4l-46.2-45 46.2-45c6.2-6.1 8.7-15 6.4-23.4s-8.9-14.9-17.3-17.1l-62.5-15.8 17.5-62c2.4-8.4 0-17.4-6.1-23.5s-15.1-8.5-23.5-6.1l-62 17.5L341.4 18.1c-2.1-8.4-8.7-15-17.1-17.3S307 1 301 7.3L256 53.5 211 7.3z" />
      </svg>
    </span>,
    linkTo: "/organisationCertificates",
    component: CertificatesContainer
  },
  {
    title: "Email Templates",
    iconType: () => <span className='anticon' style={{ width: "22px", fontSize: "22px" }}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path fill="currentColor" d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48L48 64zM0 176L0 384c0 35.3 28.7 64 64 64l384 0c35.3 0 64-28.7 64-64l0-208L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z" />
      </svg>
    </span>,
    linkTo: "/manage_email_templates",
    component: EmailTemplateContainer
  },
  {
    iconType: () => <span className='anticon' style={{ width: "22px", fontSize: "22px" }}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path fill="currentColor" d="M0 64C0 28.7 28.7 0 64 0L224 0l0 128c0 17.7 14.3 32 32 32l128 0 0 144-208 0c-35.3 0-64 28.7-64 64l0 144-48 0c-35.3 0-64-28.7-64-64L0 64zm384 64l-128 0L256 0 384 128zM176 352l32 0c30.9 0 56 25.1 56 56s-25.1 56-56 56l-16 0 0 32c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-48 0-80c0-8.8 7.2-16 16-16zm32 80c13.3 0 24-10.7 24-24s-10.7-24-24-24l-16 0 0 48 16 0zm96-80l32 0c26.5 0 48 21.5 48 48l0 64c0 26.5-21.5 48-48 48l-32 0c-8.8 0-16-7.2-16-16l0-128c0-8.8 7.2-16 16-16zm32 128c8.8 0 16-7.2 16-16l0-64c0-8.8-7.2-16-16-16l-16 0 0 96 16 0zm80-112c0-8.8 7.2-16 16-16l48 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0 0 32 32 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0 0 48c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-64 0-64z" />
      </svg>
    </span>,
    linkTo: "/pdf_template_management",
    component: PdfTemplateContainer
  },
  {
    iconType: (icon) => <i className="anticon material-icons">{icon ? icon : "account_balance"}</i>,
    linkTo: "/timesheet-management",
    component: ShiftTimeSheetContainer
  },
  {
    title: "SWMS Management 2",
    iconType: () => <i className="anticon material-icons">event_seat</i>,
    theme: "filled",
    linkTo: "/org_swms_management",
    component: SWMSMastersContainer
  },
  {
    title: "Report Management",
    iconType: (icon) => <i className="anticon material-icons">{icon ? icon : "description"}</i>,
    theme: "filled",
    linkTo: "/report-management",
    component: OHSMastersContainer
  },
  {
    iconType: () => <span className='anticon' style={{ width: "22px", fontSize: "22px" }}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
        <path fill="currentColor" d="M64 64C28.7 64 0 92.7 0 128V384c0 35.3 28.7 64 64 64H512c35.3 0 64-28.7 64-64V128c0-35.3-28.7-64-64-64H64zM272 192H496c8.8 0 16 7.2 16 16s-7.2 16-16 16H272c-8.8 0-16-7.2-16-16s7.2-16 16-16zM256 304c0-8.8 7.2-16 16-16H496c8.8 0 16 7.2 16 16s-7.2 16-16 16H272c-8.8 0-16-7.2-16-16zM164 152v13.9c7.5 1.2 14.6 2.9 21.1 4.7c10.7 2.8 17 13.8 14.2 24.5s-13.8 17-24.5 14.2c-11-2.9-21.6-5-31.2-5.2c-7.9-.1-16 1.8-21.5 5c-4.8 2.8-6.2 5.6-6.2 9.3c0 1.8 .1 3.5 5.3 6.7c6.3 3.8 15.5 6.7 28.3 10.5l.7 .2c11.2 3.4 25.6 7.7 37.1 15c12.9 8.1 24.3 21.3 24.6 41.6c.3 20.9-10.5 36.1-24.8 45c-7.2 4.5-15.2 7.3-23.2 9V360c0 11-9 20-20 20s-20-9-20-20V345.4c-10.3-2.2-20-5.5-28.2-8.4l0 0 0 0c-2.1-.7-4.1-1.4-6.1-2.1c-10.5-3.5-16.1-14.8-12.6-25.3s14.8-16.1 25.3-12.6c2.5 .8 4.9 1.7 7.2 2.4c13.6 4.6 24 8.1 35.1 8.5c8.6 .3 16.5-1.6 21.4-4.7c4.1-2.5 6-5.5 5.9-10.5c0-2.9-.8-5-5.9-8.2c-6.3-4-15.4-6.9-28-10.7l-1.7-.5c-10.9-3.3-24.6-7.4-35.6-14c-12.7-7.7-24.6-20.5-24.7-40.7c-.1-21.1 11.8-35.7 25.8-43.9c6.9-4.1 14.5-6.8 22.2-8.5V152c0-11 9-20 20-20s20 9 20 20z" />
      </svg>
    </span>,
    linkTo: "/expense-accounts",
    component: ExpenseAccountsContainer
  },
  {
    title: "Contracts",
    iconType: () => <span className='anticon' style={{ width: "22px", fontSize: "22px" }}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
        <path fill="currentColor" d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM80 64h64c8.8 0 16 7.2 16 16s-7.2 16-16 16H80c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64h64c8.8 0 16 7.2 16 16s-7.2 16-16 16H80c-8.8 0-16-7.2-16-16s7.2-16 16-16zm54.2 253.8c-6.1 20.3-24.8 34.2-46 34.2H80c-8.8 0-16-7.2-16-16s7.2-16 16-16h8.2c7.1 0 13.3-4.6 15.3-11.4l14.9-49.5c3.4-11.3 13.8-19.1 25.6-19.1s22.2 7.7 25.6 19.1l11.6 38.6c7.4-6.2 16.8-9.7 26.8-9.7c15.9 0 30.4 9 37.5 23.2l4.4 8.8H304c8.8 0 16 7.2 16 16s-7.2 16-16 16H240c-6.1 0-11.6-3.4-14.3-8.8l-8.8-17.7c-1.7-3.4-5.1-5.5-8.8-5.5s-7.2 2.1-8.8 5.5l-8.8 17.7c-2.9 5.9-9.2 9.4-15.7 8.8s-12.1-5.1-13.9-11.3L144 349l-9.8 32.8z" />
      </svg>
    </span>,
    linkTo: "/contracts",
    component: ContractsContainer
  },
  {
    title: "Tasks",
    iconType: () => <span className='anticon' style={{ width: "22px", fontSize: "22px" }}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path fill="currentColor" d="M152.1 38.2c9.9 8.9 10.7 24 1.8 33.9l-72 80c-4.4 4.9-10.6 7.8-17.2 7.9s-12.9-2.4-17.6-7L7 113C-2.3 103.6-2.3 88.4 7 79s24.6-9.4 33.9 0l22.1 22.1 55.1-61.2c8.9-9.9 24-10.7 33.9-1.8zm0 160c9.9 8.9 10.7 24 1.8 33.9l-72 80c-4.4 4.9-10.6 7.8-17.2 7.9s-12.9-2.4-17.6-7L7 273c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l22.1 22.1 55.1-61.2c8.9-9.9 24-10.7 33.9-1.8zM224 96c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H256c-17.7 0-32-14.3-32-32zm0 160c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H256c-17.7 0-32-14.3-32-32zM160 416c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H192c-17.7 0-32-14.3-32-32zM48 368a48 48 0 1 1 0 96 48 48 0 1 1 0-96z" />
      </svg>
    </span>,
    linkTo: "/tasks",
    component: TasksContainer
  },
];

export const NAV_MENU_ICONS = NAV_MENU.map(({ iconType, linkTo }) => ({ iconType, linkTo }));