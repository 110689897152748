import React from "react";
import { Page, Text, View, Document, StyleSheet, Image, Link } from "@react-pdf/renderer";
import { Html } from 'react-pdf-html';
import downloadIcon from './download-icon.png'
import moment from "moment";
import "./jobReportPdf.css";

// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: "#ffffff",
    paddingTop: 10,
    paddingBottom: 20,
    fontSize: 10,
    fontWeight: "normal",
  },

  comtext: {
    color: "#4A4A4A",
    fontWeight: "normal",
    fontSize: 10,
    paddingBottom: 3,
  },
  comtextBold: {
    color: "#13619a",
    fontWeight: "normal",
    fontSize: 9,
    paddingBottom: 3,
  },
  textRight: { textAlign: "right" },
  textCenter: { textAlign: "center" },
  row: {
    flexDirection: "row",
    flexWrap: "wrap",
  },

  formField: {
    flexGrow: 0.2,
    marginTop: 16,
    padding: 5,

    marginRight: 10,
    marginLeft: 15,
  },
  formInput: {
    flexGrow: 0.7,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    borderBottomLeftRadius: 4,
    border: 2,
    backgroundColor: "#fff",
    borderTopStyle: "solid",
    borderBottomStyle: "solid",
    borderLeftStyle: "solid",
    borderRightStyle: "solid",
    borderLeftColor: "#EFEFEF",
    borderRightColor: "#EFEFEF",
    borderTopColor: "#EFEFEF",
    borderBottomColor: "#EFEFEF",
    margin: 10,
    marginRight: 10,
    padding: 5,
    marginLeft: 10,
  },

  formInputTextArea: {
    flexGrow: 0.73,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    borderBottomLeftRadius: 4,
    border: 2,
    backgroundColor: "#fff",
    borderTopStyle: "solid",
    borderBottomStyle: "solid",
    borderLeftStyle: "solid",
    borderRightStyle: "solid",
    borderLeftColor: "#EFEFEF",
    borderRightColor: "#EFEFEF",
    borderTopColor: "#EFEFEF",
    borderBottomColor: "#EFEFEF",
    margin: 10,
    marginRight: 10,
    padding: 5,
    marginLeft: 20,
    height: 60,
  },

  rowImage: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 100,
  },
  sliderMain: {
    flexDirection: "row",
    justifyContent: "center",
    marginTop: 60,
  },
  slider: {
    flexDirection: "row",
    justifyContent: "center",
  },
  imageContainer: {
    border: 1,
    width: 150,
    height: 150,
  },
  header: {
    backgroundColor: "#F8F8F8",
    padding: 20,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },

  smCard: {
    float: "left",
    textAlign: "left",
    backgroundColor: "",
    borderRight: "1px solid #f3f3f3",
    padding: 10,
    flexGrow: 1,
  },
  card: {
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    borderBottomLeftRadius: 4,
    border: 2,
    backgroundColor: "#fff",
    borderTopStyle: "solid",
    borderBottomStyle: "solid",
    borderLeftStyle: "solid",
    borderRightStyle: "solid",
    borderLeftColor: "#EFEFEF",
    borderRightColor: "#EFEFEF",
    borderTopColor: "#EFEFEF",
    borderBottomColor: "#EFEFEF",
    marginTop: 10,
    marginRight: 10,

    marginLeft: 10,
  },
  cardDoc: {
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    borderBottomLeftRadius: 4,
    border: 2,
    backgroundColor: "#fff",
    borderTopStyle: "solid",
    borderBottomStyle: "solid",
    borderLeftStyle: "solid",
    borderRightStyle: "solid",
    borderLeftColor: "#EFEFEF",
    borderRightColor: "#EFEFEF",
    borderTopColor: "#EFEFEF",
    borderBottomColor: "#EFEFEF",
    marginTop: 20,
    marginRight: 10,

    marginLeft: 10,
  },
  cardHeading: {
    padding: 10,
    fontSize: 12,
    fontWeight: 500,
    color: "#13619A",

    backgroundColor: "#f3f3f3",
  },
  textSlider: {
    paddingTop: 40,
    flexGrow: 1,
  },

  section: {
    // margin: 10,
    padding: 10,

    flexGrow: 1,
  },

  section2: {
    // margin: 10,
    padding: 10,
  },
  boldTextHeading: {
    color: "#121212",
    fontSize: "11px",

    marginBottom: "4px",
  },
  sectionHeading: {
    paddingTop: 40,
    paddingLeft: 20,
    fontSize: 30,
    flexGrow: 1,
  },
  sectionLogo: {
    textAlign: "right",
    padding: 14,
  },
  sectionLogoRight: {
    textAlign: "left",
  },
  comLogo: {
    maxHeight: '80px',
    objectFit: 'contain'
  },
  comLogoRotate: {
    width: 100,
    height: 80,
    transform: 'rotate(90deg)',
    padding: 3,

  },
  imageText: {
    position: "absolute",
  },
  // table
  tableWrpa: {
    padding: 10,
    width: "100%",

    marginTop: "4px"
  },
  table: {
    display: "table",
    width: "100%",
  },
  tr: {
    flexDirection: "row",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#EFEFEF",
    borderLeftWidth: 0,
    borderBottomWidth: 0,
    borderRightWidth: 0,
  },
  th: { padding: 2, paddingBottom: 8, paddingLeft: 4, paddingRight: 10 },

  td: {
    padding: 4,
    paddingLeft: 4,
    paddingRight: 2,
    borderLeft: "2px solid #f3f3f3",
  },
  cellText: {
    fontSize: 10,
    color: "#4a4a4a",
  },

  tCol1: { width: "8.333333%" },
  tCol2: { width: "16.666667%", marginHorizontal: 1 },
  tCol3: { width: "22%" },
  tCol4: { width: "36.33%" },
  tCol5: { width: "41.666667%%" },
  tCol6: { width: "50%" },
  tCol7: { width: "58.333333%" },
  tCol8: { width: "66.666666%" },
  tCol9: { width: "75%" },
  tCol10: { width: "83.333333%" },
  tCol11: { width: "91.666667%" },
  tCol12: { width: "100%" },
  h4: {
    fontSize: 16,
    marginBottom: 5,
  },
  h3: {
    fontSize: 18,
    marginBottom: 8,
  },
  li: {
    fontSize: 12,
    marginBottom: 8,
  },
  rownumber: {
    width: 200,
  },
  lable: {
    fontSize: 12,
    marginBottom: 5,
  },
  span: {
    fontSize: 12,
    marginBottom: 5,
    color: "#000",
  },
  thFirst: {
    width: 420,
  },
  thead: {
    backgroundColor: "#424242",
    color: "#fff",
  },
  balanceDue: {
    width: 200,
    marginLeft: 250,
  },
  balanceMain: {
    borderTopWidth: 1,
    borderTopColor: "#919191",
    borderTopStyle: "solid",

    borderBottomWidth: 1,
    borderBottomColor: "#919191",
    borderBottomStyle: "solid",
    marginLeft: 20,
    marginRight: 20,
    textAlign: "right",
    with: "100%",
    lineHeight: 0,
  },
  details: {
    marginLeft: 20,
    marginRight: 20,
    fontSize: 12,
    marginBottom: 5,
  },
  lableBig: {
    fontSize: 15,
    marginBottom: 0,
    lineHeight: 0,
  },
  notes: {
    marginLeft: 20,
    marginRight: 20,
    color: "#919191",
    fontSize: 13,
    marginTop: 10,
  },
  sfCard: {
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    borderBottomLeftRadius: 4,
    border: 1,
    borderTopStyle: "solid",
    borderBottomStyle: "solid",
    borderLeftStyle: "solid",
    borderRightStyle: "solid",
    borderLeftColor: "#EFEFEF",
    borderRightColor: "#EFEFEF",
    borderTopColor: "#EFEFEF",
    borderBottomColor: "#EFEFEF",
    marginBottom: 20,
    marginTop: 40,
  },

  brTopNon: { borderTopWidth: 0 },
  rowHeader: {
    padding: "0px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: 3,
    borderStyle: "solid",
    borderColor: "#13619A",
    marginHorizontal: "10px",
  },
  comtextB: {
    color: "#000000",
    fontWeight: "700",
    fontSize: 16,
    paddingBottom: 3,
  },
  accordian: {
    padding: "5px",
    paddingLeft: "8px",
    borderBottom: 2,

    marginTop: "5px",
    borderStyle: "solid",
    borderColor: "#f1f1f1",
  },
  downloadIcon: {
    fontSize: 15,
    marginTop: 8

  },
  noteText: {
    paddingLeft: "5px",
    paddingRight: "5px",
  }
});

export const JobReportPdf = ({
  job,
  jobReports,
  filePath,
  resizedFilePath,
  jobDocument,
  allTasks,
  isDoc
}) => {


  return (
    <Document>
      <Page size="A4" style={styles.page} wrap>
        <View>
          <View style={styles.rowHeader}>
            <View>
              <Image
                style={{ width: "140px", height: "auto" }}
                src={`${job.org_logo}?t=${moment(new Date())}`}
              />
            </View>
            <View>
              <Text style={styles.comtextB}> Job Report</Text>
            </View>
          </View>
        </View>
        <View style={styles.card}>
          <View style={styles.row}>
            <View style={styles.section}>
              <Text style={styles.comtext}>By</Text>
              <Text style={styles.comtextBold}>
                Documents Prepared By: <Text style={styles.comtext}>{Object.keys(job).length > 0 && job.admin_name}</Text>
              </Text>
              <Text style={styles.comtextBold}>
                Company: <Text style={styles.comtext}>{Object.keys(job).length > 0 && job.org_name}</Text>
              </Text>
              <Text style={styles.comtextBold}>
                Address: <Text style={styles.comtext}>{Object.keys(job).length > 0 && job.address}</Text>
              </Text>
              <Text style={styles.comtextBold}>
                Mobile: <Text style={styles.comtext}>{Object.keys(job).length > 0 && job.admin_phone_number}</Text>
              </Text>
              <Text style={styles.comtextBold}>
                Email: <Text style={styles.comtext}>{Object.keys(job).length > 0 && job.admin_email_address}</Text>
              </Text>
            </View>
            <View style={styles.section2}>
              <Text style={styles.comtext}>To</Text>
              <Text style={styles.comtextBold}>
                Attention: <Text style={styles.comtext}>{Object.keys(job).length > 0 && job.client_person_name}</Text>
              </Text>
              <Text style={styles.comtextBold}>
                Company: <Text style={styles.comtext}>{Object.keys(job).length > 0 && job.client_name}</Text>
              </Text>
              <Text style={styles.comtextBold}>
                Address: <Text style={styles.comtext}>{Object.keys(job).length > 0 && job.client_address}</Text>
              </Text>
              <Text style={styles.comtextBold}>
                Mobile: <Text style={styles.comtext}>{Object.keys(job).length > 0 && job.client_person_phone}</Text>
              </Text>
              <Text style={styles.comtextBold}>
                Email: <Text style={styles.comtext}>{job ? job.client_person_email : " "}</Text>
              </Text>
            </View>
          </View>
        </View>
        {job &&
          job.sites.map((site) => {

            return <View style={styles.card} wrap>
              <Text style={styles.cardHeading}>Job Details</Text>
              <View style={styles.sfCardwe}>
                <View style={styles.tableWrpa}>
                  <View style={styles.table}>
                    <View style={[styles.tr, styles.brTopNon]} fixed>
                      <View style={[styles.th, styles.tCol4]}>
                        <Text style={styles.cellHdText}>Site Name</Text>
                      </View>
                      <View style={[styles.th, styles.tCol4]}>
                        <Text style={styles.cellHdText}>Site Address</Text>
                      </View>
                      <View style={[styles.th, styles.tCol4]}>
                        <Text style={styles.cellHdText}>{" "}</Text>
                      </View>
                    </View>
                    <View style={styles.tr}>
                      <View style={[styles.td, styles.tCol4]}>
                        <Text style={styles.comtext}>{site.site_name}{" "}</Text>
                      </View>
                      <View style={[styles.td, styles.tCol4]}>
                        <Text style={styles.comtext}>{site.street_address} {site.city},{site.state},{site.country}{" "}</Text>
                      </View>
                      <View style={[styles.td, styles.tCol4]}>
                        <View >
                          <Text style={styles.comtext}>
                            Quote Number: <Text style={styles.comtext}> {job.quote_number}</Text>
                          </Text>
                          <Text style={styles.comtext}>
                            Purchase Order: <Text style={styles.comtext}> {job.PO_number}</Text>
                          </Text>
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          })}

        <View style={styles.card}>
          <Text style={styles.cardHeading}>Job Details</Text>
          {jobReports.map((report, index) => {
            return <View style={styles.sfCardwe} break={index.toString() === "2" ? true : false} >
              <Text style={styles.accordian}>Task -  {allTasks &&
                allTasks.find(
                  (tsk) => tsk.id === report.quote_task_id
                ) &&
                allTasks.find(
                  (tsk) => tsk.id === report.quote_task_id
                ).task_name}</Text>
              <View style={styles.tableWrpa}>
                <View style={styles.table}>
                  <View style={[styles.tr, styles.brTopNon]} fixed>
                    <View style={[styles.th, styles.tCol3]}>
                      <Text style={styles.cellHdText}>Area Photos</Text>
                    </View>
                    <View style={[styles.th, styles.tCol3]}>
                      <Text style={styles.cellHdText}>Before Photos</Text>
                    </View>
                    <View style={[styles.th, styles.tCol3]}>
                      <Text style={styles.cellHdText}>After Photos</Text>
                    </View>
                    <View style={[styles.th, styles.tCol4]}>
                      <Text style={styles.cellHdText}>Note</Text>
                    </View>
                  </View>

                  <View style={styles.tr}>
                    <View style={[styles.td, styles.tCol3]}>
                      <View>
                        {report.location_photo && JSON.parse(report.location_photo).length > 0 ?
                          JSON.parse(report.location_photo).map(link =>
                            <View>

                              <Link target="_blank" src={filePath + link}>


                                <Image style={styles.comLogo} src={resizedFilePath + link + "?t=" + moment(new Date())} />
                              </Link>
                            </View>) : null}
                      </View>
                    </View>
                    <View style={[styles.td, styles.tCol3]}>
                      {report.before_photo && JSON.parse(report.before_photo).length > 0 ?
                        JSON.parse(report.before_photo).map(link =>
                          <Link target="_blank" src={filePath + link}>
                            <Image style={styles.comLogo} src={resizedFilePath + link + "?t=" + moment(new Date())} />

                          </Link>
                        ) : null}
                    </View>
                    <View style={[styles.td, styles.tCol3]}>
                      {report.after_photo && JSON.parse(report.after_photo).length > 0 ?
                        JSON.parse(report.after_photo).map(link =>
                          <Link target="_blank" src={filePath + link}>
                            <Image style={styles.comLogo} src={resizedFilePath + link + "?t=" + moment(new Date())} />

                          </Link>
                        ) : null}
                    </View>
                    <View style={[styles.td, styles.tCol4]}>
                      <Text style={styles.cellText}>{report.note !== null ? report.note : ''}</Text>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          })}
        </View>

        {isDoc && jobDocument.length > 0 &&
          <View style={styles.card} wrap break>
            <Text style={styles.cardHeading}>Additional Documents</Text>

            <View style={styles.sfCardwe}>
              <View style={styles.tableWrpa}>
                <View style={styles.table}>
                  <View style={[styles.tr, styles.brTopNon]} fixed>
                    <View style={[styles.th, styles.tCol4]}>
                      <Text style={styles.cellHdText}>Document Name</Text>
                    </View>
                    <View style={[styles.th, styles.tCol4]}>
                      <Text style={styles.cellHdText}>Document Comments</Text>
                    </View>
                    <View style={[styles.th, styles.tCol4]}>
                      <Text style={styles.cellHdText}>Action</Text>
                    </View>
                  </View>
                  {jobDocument && jobDocument.map((doc) => {
                    return <View style={styles.tr}>
                      <View style={[styles.td, styles.tCol4]}>
                        <Text style={styles.comtext}>{doc.document_name}{" "}</Text>
                      </View>
                      <View style={[styles.td, styles.tCol4]}>
                        <Html style={{ fontSize: "10px" }}>{doc.comments}</Html>
                      </View>
                      <View style={[styles.td, styles.tCol4]}>
                        <Text style={styles.downloadIcon}>
                          <Link target="_blank" src={doc.shift_report_document}>
                            <Image src={downloadIcon}></Image>
                          </Link>
                        </Text>
                      </View>
                    </View>
                  })}
                </View>
              </View>
            </View>
          </View>}
      </Page>
    </Document>
  );
};
export default JobReportPdf;
